import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export enum AuthRoutingPaths {
    RESET_PASSWORD = 'reset-password',
    UNSUBSCRIBE = 'unsubscribe'
}

const authRoutes: Routes = [
    {
        path: AuthRoutingPaths.RESET_PASSWORD,
        loadComponent: () =>
            import('./pages/reset-password/reset-password-page.component').then(
                (c) => c.ResetPasswordPageComponent
            )
    },
    {
        path: AuthRoutingPaths.UNSUBSCRIBE,
        loadComponent: () =>
            import('./pages/unsubscribe/unsubscribe-page.component').then(
                (c) => c.UnsubscribePageComponent
            )
    }
];

@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
